import React, { useState, useEffect, useMemo } from "react"
import styled from "styled-components"
import { useShoppingCart } from 'use-shopping-cart'
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import {
  StyledProductPrimaryCard,
  ProductHeader,
  ProductExcerpt,
  ProductFigure,
  ProductFigureCaption
} from "./StyledPrimaryProductCard"
import { StyledModal,StyledModalHeader } from "../../styledComponents/modal"
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import { StyledButton, StyledButtonLink, StyledCloseModalButton, StyledLinkButton } from "../../styledComponents/button"
import Icon from "../../components/icon"
import KarinaGrantLogo from "../logo"
import AudioCard from "../../components/App/audioCard" 
import theEssentialMindfulnessCollection from "../../fixtures/mindfulness-collection"
import qtMorningMeditation from "../../fixtures/qt-morning-meditation"
import chakraBalancingCollection from "../../fixtures/chakra-balancing-collection"
import qtBrainHealing from "../../fixtures/qt-brain-healing"
import qtBalancingStructure from "../../fixtures/qt-balancing-structure"
import qtChakraBalancing from "../../fixtures/qt-chakra-balancing"
import lunchtimeReset from "../../fixtures/mindfulness-collection-lunchtime-reset"
import shm from "../../fixtures/self-healing-mastery"
import permissionSleep from "../../fixtures/permission-to-sleep"
import permissionResetAnxiety from "../../fixtures/permission-to-reset-anxiety"
import permissionBonus from "../../fixtures/permission-bonus"
import permissionResetNervousSystem from "../../fixtures/permission-to-reset-nervous-system"
import permissionResetNervousSystemSessionOneOnline from "../../fixtures/permission-to-reset-nervous-system-online"
import spoonTherapy from "../../fixtures/spoon-therapy"
import commandHealing from "../../fixtures/command-healing"
import ShmProgram from "../../components/shmProgram"
import SleepProgram from "../../components/sleepProgram"
import ResetAnxietyProgram from "../../components/resetAnxietyProgram"
import PermissionBonus from "../../components/resetAnxietyProgram"
import ResetProgram from "../../components/resetProgram"
import { StyledAudio } from "../../styledComponents/app/audioCard"
import AudioModal from "../../components/App/AudioModal"
import { blobToArrayBuffer, getFixtureFunc } from "../../functions/utils"
import programmesObj from "../../functions/programmes"
import localforage from "localforage"
import axios from 'axios'
import axiosRetry from 'axios-retry';
import { DownloadIcon, DownloadBanner, DownloadMessage, ErrorBanner, DownloadFailedBanner, OfflineWarningBanner } from "../../styledComponents/offlineStorage"
import { analyticClickEvent } from "../../components/googleAnalytics"
import SpoonTherapyProgram from "../SpoonTherapy"
import CommandHealingProgram from "../CommandHealing"
import healingTuningBlueprintFixture from "../../fixtures/localFixtures/healing-tuning-blueprint"
import FmtjHealingTuningBlueprintProgram from "../fmtjHumaTuningBlueprint"

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 1000;
  },
  retryCondition: (err) => !err.response || err.response.status === 500,
});

const possibleProgrammes = ['cG9zdDo3NzY5', 'cG9zdDo3MjM0', 'cG9zdDo2OTE0', 'cG9zdDo2OTcx', 'cG9zdDo2MzU4', 'cG9zdDo2MzU0', 'cG9zdDo2MzQ4', 'cG9zdDo2MzY1', 'cG9zdDo3NzQz']

function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [hasError, setError] = useState();
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [showDownloadBanner, setShowDownloadBanner] = useState(false);
  const [failedDownload, setFailedDownload] = useState(false);
  const [downloadedLocally, setDownloadedLocally] = useState(false);
  const [programmeLocal, setProgrammeLocal] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [collectionLocal, setCollectionLocal] = useState();
  const [opacity, setOpacity] = useState(0);
  const [locallyDownloaded, setLocallyDownloaded] = useState([])

  const shouldShowDownload = useMemo(() => possibleProgrammes.includes(data.id), [data])

  useEffect(() => {
    if (localStorage) {
      setLocallyDownloaded((localStorage.getItem('downloadedItems') || '').split(','))
    }
  }, [data, isOpen])

  useEffect(() => {
    async function checkLocalForageKey() {
      if (data && programmeLocal) {
        setIsDownloaded(true)
      }
    }

    checkLocalForageKey()
  }, [data, programmeLocal])

  async function getLocalForageItem() {
    const downloadedKeys = (localStorage.getItem('downloadedItems') || '').split(',').filter(a => a)
    const localItem = (await localforage.keys() || []).includes(data.id);
    if (localItem) {
      const audioLength = Object.keys((await localforage.getItem(data.id))?.audio || {}).length
      const videoLength = Object.keys((await localforage.getItem(data.id))?.video || {}).length
      const programmeConst = programmesObj[data.id];
      const isAudioFilesEqual = Object.keys(programmeConst.audio).length === audioLength
      const isVideoFilesEqual = Object.keys(programmeConst.video).length === videoLength
      if (!isAudioFilesEqual || !isVideoFilesEqual) setFailedDownload(true)
      if (!downloadedKeys || !downloadedKeys.includes(data.id)) localStorage.setItem('downloadedItems', [...downloadedKeys || [], data.id].join(','))
      setProgrammeLocal(true)
    }
  }

  function getLocallyDownloaded() {
    if (data && data.id) {
      const localDownloadArr = (localStorage.getItem('downloadedLocally') || '').split(',').filter(a => a)
      if (localDownloadArr && localDownloadArr.includes(data.id)) setDownloadedLocally(true)
    }
  }

  async function toggleModal(e) {
    const isOpening = isOpen === false;
    setOpacity(0);
    setIsOpen(!isOpen);
    if (isOpening) {
      if (!programmeLocal) await getLocalForageItem()
      getLocallyDownloaded()
      const localCollectionRes = await getFixtureFunc(data.id)
      if (localCollectionRes) setCollectionLocal(localCollectionRes)
    }
  }
  
  function closeModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen); 
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  async function getProgramme(url, isInfo = false) {
    try {
      const response = await axios({
        method: isInfo ? 'HEAD' : 'GET',
        url,
        responseType: isInfo ? "json" : "blob",
        headers: {
          'Cache-Control': 'no-cache'
        },
      })
      if (isInfo && response.headers) {
        return response['headers']['content-length'] || 0;
      }
  
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  async function removeProgramme() {
    setFailedDownload(false)
    await localforage.removeItem(data.id).catch(err => console.error(err))
    setIsDownloaded(false)
    const downloadedKeyItems = (localStorage.getItem('downloadedItems') || '').split(',').filter(a => a)
    localStorage.setItem('downloadedItems', downloadedKeyItems.filter(a => a !== data.id).join(','))
  }

  async function saveProgramme() {
    setShowDownloadBanner(true);
    setIsDownloading(true);

    // Download & set background image used throughout if one item not already downloaded
    if (!isDownloaded || !programmeLocal) {
      const downloadedKeys = await localforage.keys()
      if (!downloadedKeys || downloadedKeys.length === 0) {
        const { data: sleepStarsBackground } = await getProgramme(`${window.location.origin}/app/images/permission-to-sleep/sleep-stars.jpg`)
        if (sleepStarsBackground) await localforage.setItem('sleepStarsBackground', sleepStarsBackground)
      }
    }

    const foundProgramme = programmesObj[data.id];
    const { video = {}, audio = {} } = foundProgramme;
    const videoEntries = Object.entries(video), audioEntries = Object.entries(audio);
    const totalDownloads = videoEntries.length + audioEntries.length;
    const downloadStepSize = (100 / totalDownloads).toFixed(2);

    // Calculate total programme size
    const videoSizesRes = await Promise.all(Object.values(video).map(vUrl => getProgramme(vUrl, true))) || [];
    const audioSizesRes = await Promise.all(Object.values(audio).map(aUrl => getProgramme(aUrl, true))) || [];
    const totalProgrammeSize = [...videoSizesRes, ...audioSizesRes].reduce((acc, val) => acc + Number.isNaN(+val) ? 0 : +val, 0);

    if (navigator?.storage && totalProgrammeSize > (await navigator.storage.estimate()).quota) {
      // TODO Display alert to show the user doesn't have enough IndexedDB space
      setShowDownloadBanner(false);
      setIsDownloading(false);
      return;
    }

    let failedCount = 0;
    let videosRes = [], audiosRes = [];
    for (const videoI of videoEntries) {
      const [key, val] = videoI;
      const res = await getProgramme(val);
      if (!res) {
        failedCount++
        setDownloadProgress(prevState => Math.floor(prevState + +downloadStepSize))
        continue
      }
      setDownloadProgress(prevState => Math.floor(prevState + +downloadStepSize))
      videosRes.push({ key, val: res });
    }
    for (const audioI of audioEntries) {
      const [key, val] = audioI;
      const res = await getProgramme(val);
      if (!res) {
        failedCount++
        setDownloadProgress(prevState => Math.floor(prevState + +downloadStepSize))
        continue
      }
      setDownloadProgress(prevState => Math.floor(prevState + +downloadStepSize))
      audiosRes.push({ key, val: res });
    }

    if (failedCount > 0) {
      setError(`Failed to download ${failedCount} items, removing the programme and redownloading may fix this.`)
      setFailedDownload(true)
    } else if (failedDownload) setFailedDownload(false)
    let finalObj = { video: {}, audio: {} }
    for (const aRes of audiosRes) {
      if (!aRes || !aRes.val) continue;
      finalObj.audio[aRes.key] = aRes.val.data;
    }
    for (const vRes of videosRes) {
      if (!vRes || !vRes.val) continue;
      finalObj.video[vRes.key] = vRes.val.data;
    }
    localStorage.setItem('downloadedItems', [...(localStorage.getItem('downloadedItems') || '').split(',').filter(a => a) || [], data.id].join(','))
    await localforage.setItem(data.id, finalObj);
    setIsDownloading(false);
    setDownloadProgress(100);
    setIsDownloaded(true);
    const localCollectionRes = await getFixtureFunc(data.id)
    if (localCollectionRes) setCollectionLocal(localCollectionRes)
  }

  async function downloadLocalProgramme() {
    // TODO Get zipped folder of all videos/audios from AWS S3
    // then trigger download for user.
    setShowDownloadBanner(true);
    setIsDownloading(true)
    console.log('hits downloadLocalProgramme')
    const response = await axios({
      method: 'GET',
      url: `https://karinagrant.s3.eu-west-1.amazonaws.com/full-course/${data.id}.zip`,
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache'
      },
      onDownloadProgress: (progressEvent) => {
        const total = progressEvent.total
        const current = progressEvent.loaded
    
        let percentCompleted = Math.floor(current / total * 100)
        setDownloadProgress(percentCompleted)
      },
    })
    const currentlyDownloadedLocal = (localStorage.getItem('downloadedLocally') || '').split(',').filter(a => a)
    localStorage.setItem('downloadedLocally', [...currentlyDownloadedLocal, data.id].join(','))
    setDownloadedLocally(true)
    setIsDownloading(false)
  }  
  
  async function removeLocalProgrammeKey() {
    const currentlyDownloadedLocal = (localStorage.getItem('downloadedLocally') || '').split(',').filter(a => a)
    localStorage.setItem('downloadedLocally', currentlyDownloadedLocal.filter(a => a !== data.id).join(','))
    setDownloadedLocally(false)
  }

  let collection;
  if(data.id == 'cG9zdDo2OTE0') collection = theEssentialMindfulnessCollection
  if(data.id == 'cG9zdDo2OTcx') collection = chakraBalancingCollection
  if(data.id == 'cG9zdDo2MzU4') collection = qtMorningMeditation
  if(data.id == 'cG9zdDo2MzU0') collection = qtBrainHealing
  if(data.id == 'cG9zdDo2MzQ4') collection = qtBalancingStructure
  if(data.id == 'cG9zdDo2MzY1') collection = qtChakraBalancing
  if(data.id == 'cG9zdDo3Mzcz') collection = lunchtimeReset
  if(data.id == 'cG9zdDo3NzQz') collection = shm
  if(data.id == 'cG9zdDo3NzY5') collection = permissionSleep;
  if(data.id == 'cG9zdDo3MjM0') collection = permissionResetAnxiety
  if(data.id == 'cG9zdDo3OTI5') collection = permissionBonus
  if(data.id == 'cG9zdDo3ODE2') collection = permissionResetNervousSystem
  if(data.id == 'cG9zdDo5MDkz') collection = permissionResetNervousSystemSessionOneOnline
  if(data.id == 'cG9zdDo5NjIx') collection = spoonTherapy
  if(data.id == 'cG9zdDo4FDkz') collection = commandHealing
  if(data.id == 'cG9zdDo5Nj4x') collection = healingTuningBlueprintFixture

  useEffect(() => {
    if (hasError) setTimeout(() => setError(''), 7000)
  }, [hasError])

  useEffect(() => {
    if (isDownloaded) setTimeout(() => {
      setShowDownloadBanner(false)
      setDownloadProgress(0)
    }, 4000)
  }, [isDownloaded])

  const image = {
    imageData: data.image,
    alt: ``,
    description: "",
  }

  return (
      <>
       <StyledProductPrimaryCard
        itemScope
        itemType="http://schema.org/Product"
        onClick={toggleModal}
      >
        {image && image.imageData && (
              <ProductFigure>
                <GatsbyImage
                  image={image.imageData}
                  alt={image.alt}
                  width="100%"
                  padding-bottom="0" />
                <ProductFigureCaption>{image.description}</ProductFigureCaption>
              </ProductFigure>
            )}
        <ProductHeader itemProp="name">{data.title}</ProductHeader>
        <ProductExcerpt>{parse(data.excerpt)}</ProductExcerpt>
          {data.price === 0 ? 
          <div className={`${data.price === 0 ? 'product-price free': 'product-price'}`} itemProp="offers" itemScope itemType={`${data.price === 0 ? 'https://schema.org/AggregateOffer': ''}`}>
          <span>{data.price === 0 ? 'Free' : ''}</span>
            <span className="hidden" itemProp="lowPrice">0</span>
            <span itemProp="priceCurrency" className="hidden">GBP</span>
          </div>
          : null }
        <StyledButton className="listen-now">{data.productTag === 'spoontherapy' ? 'Watch' : 'Listen'}</StyledButton>
      </StyledProductPrimaryCard>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className={
          collection[0].type == 'shm-program' ? 'shm-program-modal' : 
          collection[0].type == 'spoon-therapy' ? 'spoon-therapy-modal' : 
          collection[0].type == 'permission-reset-sleep' || collection[0].type == 'permission-reset-anxiety' ? 'permission-reset-sleep-modal' : 'audio-list-modal' }
      >
        <StyledModalHeader type={collection[0].type}>
          <div>
            <KarinaGrantLogo />
            <StyledCloseModalButton className="close-audio" onClick={closeModal}>
              <Icon name="cross" />
            </StyledCloseModalButton>
            {shouldShowDownload && (
              <>
                {isDownloaded ? (
                  <DownloadIcon  onClick={() => { removeProgramme(); analyticClickEvent("click","audio", 'remove '+ data.title + ' - ' + data.userId.user.sub)}}>
                    <svg version="1.1" className="delete-local" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 443 443" xmlSpace="preserve"><g><path d="M321.785,38h-83.384V0H125.169v38H41.785v60h280V38z M155.169,30h53.232v8h-53.232V30z"/><path d="M295.142,214.31l5.66-86.31H62.769l19.016,290h114.172c-14.861-21.067-23.602-46.746-23.602-74.43 C172.355,274.43,226.849,217.779,295.142,214.31z"/><path d="M301.785,244.141c-54.826,0-99.43,44.604-99.43,99.429S246.959,443,301.785,443s99.43-44.604,99.43-99.43 S356.611,244.141,301.785,244.141z M355.961,376.533l-21.213,21.213l-32.963-32.963l-32.963,32.963l-21.213-21.213l32.963-32.963 l-32.963-32.963l21.213-21.213l32.963,32.963l32.963-32.963l21.213,21.213l-32.963,32.963L355.961,376.533z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                  </DownloadIcon>
                ) : (
                  <DownloadIcon disabled={isDownloading || !navigator.onLine} onClick={() => { saveProgramme(); analyticClickEvent("click","audio", 'download '+ data.title + ' - ' + data.userId.user.sub)}}>
                    <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M0 8h2.2v11H0zM12 0h2.2v14H12z"/><path d="m5.987 8.242 1.555-1.555 7.071 7.07-1.555 1.556z"/><path d="m18.558 6.687 1.555 1.555-7.07 7.071-1.556-1.555zM24 8h2.2v11H24zM0 19h26.2v2.2H0z"/></g></svg>
                  </DownloadIcon>
                )}
              </>
            )}
            {shouldShowDownload && !navigator.onLine && !locallyDownloaded.includes(data.id) && (
              <OfflineWarningBanner>
                <span>It looks like you're offline and haven't downloaded this program. Please reconnect to the internet to download and allow offline play.</span>
              </OfflineWarningBanner>
            )}
            {!downloadedLocally && failedDownload && (
              <DownloadFailedBanner>
                <span>It looks like some of the downloads failed. Please click the button below to send an email to Tracy, she will be able to send you a link to download the program.</span>
                <StyledLinkButton inverse className="nomargin" href={`mailto:tracy@karinagrant.co.uk?subject=Program download failed: error id:${data.id}`}  onClick={() => { analyticClickEvent("click","downloadErrorContactTracy", data.title, data.userId.user.sub)}}>Contact Tracy</StyledLinkButton>
              </DownloadFailedBanner>
            )}
            {downloadedLocally && (
              <DownloadFailedBanner>
                <p>We've found that you've previously downloaded this program locally, to play offline please look through your files.</p>
                <p>Are we wrong?</p>
                <StyledButton onClick={removeLocalProgrammeKey}>Remove downloaded notifier</StyledButton>
                </DownloadFailedBanner>
            )}
            
          </div>
        </StyledModalHeader>

        {
          collection[0].type == 'shm-program' ? <ShmProgram localProgram={collectionLocal} /> :
          collection[0].type == 'permission-reset-sleep' ? <SleepProgram collection={collectionLocal ? collectionLocal : collection} /> :
          collection[0].type == 'permission-reset-anxiety' ? <ResetAnxietyProgram collection={collectionLocal ? collectionLocal : collection} /> :
          collection[0].type == 'permission-bonus' ? <PermissionBonus /> :
          collection[0].type == 'permission-reset-nervous-system-online' ? <permissionResetNervousSystemSessionOneOnline /> :
          collection[0].type == 'reset-program' ? <ResetProgram /> :
          collection[0].type == 'spoon-therapy' ? <SpoonTherapyProgram /> :
          collection[0].type == 'command-healing' ? <CommandHealingProgram /> :
          collection[0].type == 'healing-tuning-blueprint' ? <FmtjHealingTuningBlueprintProgram /> :
          

          <StyledAudio className={collection[0].type == 'single' && 'single-audio'}>
            {
            collection[0].type == 'single' ? <AudioModal audio={collectionLocal ? collectionLocal[0] : collection[0]}/> :
            (collectionLocal ? collectionLocal[0] : collection[0]).audioFiles.map(audio => {
                return (
                <AudioCard 
                  key={audio.title + `-1`}
                  title={audio.title}
                  image={audio.image}
                  color={audio.color}
                  audioMp3={audio.audioMp3}
                  duration={audio.duration}
                />     
              )
            })
          }
          </StyledAudio>
        }
        {hasError && <ErrorBanner isDownloading={showDownloadBanner}>{hasError}</ErrorBanner>}
        {showDownloadBanner && <DownloadBanner><DownloadMessage>
          <div class="downloading">
        <span class="custom-arrow"></span>
      </div> Downloading... {downloadProgress}%</DownloadMessage></DownloadBanner>}
        </StyledModal>
      </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;

const ProductCardHasPurchase = props => {
    const { 
      } = props
      const { addItem } = useShoppingCart()
      // console.log('props.user...',props.user.sub)
    return (
      <>
       <ModalProvider backgroundComponent={FadingBackground}>
                <FancyModalButton 
                  id={props.data.id} 
                  title={props.data.title} 
                  excerpt={props.data.excerpt} 
                  image={props.data.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
                  price={props.data.pricefull}
                  userId={props.user}
                  productTag={props.data.productTag}
                />
              </ModalProvider>
          </>
    )
}
export default ProductCardHasPurchase